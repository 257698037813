import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators'; // Add this import
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserInfoService {
  private apiUrl = environment.apiUrl;
  constructor(private msalService: MsalService, private http: HttpClient) {}

  getUserClaims(): Observable<any> {
    const account = this.msalService.instance.getActiveAccount();
    if (account) {
      // Extract claims from account's ID token
      const claims = account.idTokenClaims;
      return of(claims);
    }
    return of(null);
  }

  getUserInfo(): Observable<any> {
    // Example to get a specific claim
    return this.getUserClaims().pipe(
      map((claims) => ({
        name: claims?.name,
        email: claims?.preferred_username,
        roles: claims?.roles,
      }))
    );
  }
  // Save info into UserRole table
  createProfile(formData: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/user/profile/create`, formData);
  }
}
